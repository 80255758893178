import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
import axios from "../api/axios";

const cookies = new Cookies();
const useAuth = ({ url, onSuccess, onError }) => {
  const handleAuth = useMutation({
    mutationFn: (data) => handleFormSubmit(data),
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (err) => {
      const { status,data } = err.response
      onError?.({
        status,
        data
      });
    },
  });
  const handleFormSubmit = async (getFormData) => {
    let token = cookies.get(TOKEN_NAME);
    let fd = new FormData();
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key])) {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }
    const response = await axios({
      method: "POST",
      url: `${url}`,
      data: fd,
    });
    return response;
  };
  return handleAuth;
};

export default useAuth;
