import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../config";
import axios from "../api/axios";
const cookies = new Cookies();
const useUpload = ({
    onSuccess,
    onError,
    url = '/uploadDocument',
    paramName = 'document[]'
}) => {
    const upload = useMutation({
        mutationFn: (data) => handleFormSubmit(data),
        onSuccess: (data) => {
            onSuccess?.(data)
        },
        onError: (err) => {
            console.log(err);
            onError?.(err)
        },
    })
    const handleFormSubmit = async (getFormData) => {
        let token = cookies.get(TOKEN_NAME);
        let fd = new FormData()
        for (var key in getFormData) {
            if (typeof getFormData[key] === 'object') {
                if (getFormData?.files?.length) {
                    for (let i = 0; i < getFormData?.files?.length; i++) {
                        fd.append(paramName, getFormData.files[i]);
                    }
                } else {
                    fd.append(paramName, getFormData.file);
                }
            } else {
                fd.append(key, getFormData[key]);
            }
        }
        const response = await axios({
            method: 'POST',
            url: url,
            data: fd,
            headers: { Authorization: token ? `Bearer ${token}` : '', }
        })
        return response
    }
    return upload
}

export default useUpload