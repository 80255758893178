import React, { useState } from "react";
import { Button } from "../../components/ui";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { RxReload } from "react-icons/rx";
import toast from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import { GoogleLogin } from '@react-oauth/google';
import Cookies from 'universal-cookie';
import { ROLE, TOKEN_NAME } from "../../config";


const cookies = new Cookies
const LoginWithGoogle = () => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const loginGoogle = useAuth({
        url: "/login",
        onSuccess: (res) => {
            /**
             * email: "mukeshswamy9945@gmail.com"
             * message: "Login Success"
             * name: "sanjay bhandari"
             * profile_image: ""
             * role: "employee"
             * token: "eyJ0e"
             */
            setIsLoading(false);
            const { token, role } = res?.data?.data
            cookies.set(TOKEN_NAME, token, { path: '/' })
            cookies.set(ROLE, role, { path: '/' })
            navigate("/", { replace: true });
        },
        onError: ({ status, data }) => {
            if (status === 500) {
                toast.error("Internal server error");
            } else if (status >= 400 || status <= 499) {
                toast.error(data.message);
            }
            setIsLoading(false);

        },
    });
    const googleLoginSuccess = (tokenResponse) => {
        const { credential } = tokenResponse;
        loginGoogle.mutate({ code: credential });
    };

    const googleLoginError = (error) => {
        toast.error(error);
    };

    return (
        <>
            <div>
                <Button
                    disabled={isLoading}
                    variant="outline"
                    className=" w-[400px] py-5 h-0 absolute pointer-events-none z-20 bg-white"
                >
                    <FcGoogle className="mr-2 h-4 w-4" />
                    Login with Google
                    {isLoading && <RxReload className="ml-2 h-4 w-4 animate-spin" />}
                </Button>

                <GoogleLogin
                    width={400}
                    onSuccess={credentialResponse => {
                        googleLoginSuccess(credentialResponse);
                    }}
                    onError={() => {
                        googleLoginError('Login Failed');
                    }}
                    logo_alignment={"center"}
                />
            </div>
        </>
    );
};

export default LoginWithGoogle;
