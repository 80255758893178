export const ENVIRONMENT = "development"; //production | development
export const COMPANY_NAME = "iDigitize";
export const PROJECT_META_NAME = "iDigitize_hrms_meta";
export const config = {
  apiurl:
    ENVIRONMENT === "production"
      ? "https://api-hrms.idigitize.co/"
      : "https://api-hrms-idigitize.renderly.in/",
  // apiurl: "http://local.ambrosian.in/"
};

export const TOKEN_NAME =
  ENVIRONMENT === "development"
    ? `${PROJECT_META_NAME}_dev_token`
    : `${PROJECT_META_NAME}_prod_token`;
export const ROLE =
  ENVIRONMENT === "development"
    ? `${PROJECT_META_NAME}_dev_role`
    : `${PROJECT_META_NAME}_prod_role`;
export const USERNAME =
  ENVIRONMENT === "development"
    ? `${PROJECT_META_NAME}_dev_username`
    : `${PROJECT_META_NAME}_prod_username`;

export const GOOGLE_CLIENT_ID =
  ENVIRONMENT === "development"
    ? "388582543808-ffd0dk47qe0vsll1q6e49j61et3suk4n"
    : "388582543808-ffd0dk47qe0vsll1q6e49j61et3suk4n";

export const TAX = 175;
export const ABOVE_LIMIT_TAX = 200;
export const EXEED_MONTH_LIMIT = 300;
export const TDS = 10;
export const PERCENTILE = 100;
export const GST = "";

export const MIN_LEAVE_DATE_FOR_PL = 1;
export const MIN_LEAVE_DATE_FOR_CL = 5;

export const DISCRETIONAL_LEAVE_YEAR_IGNORE = 2022;

export const FULL_TIME_EMPLOYEE = 90;
export const PART_TIME_EMPLOYEE = 45;
export const ON_SIDE = 30;
export const REMOTE = 30;

export const NOTICE_PERIOD_EMPLOYEE = 45;
export const NOTICE_PERIOD_CONSULTANT = 30;

export const FULL_TIME_EMPLOYEE_LEAVES = {
  pl: 15,
  sl: 3,
  cl: 3,
};
export const PART_TIME_EMPLOYEE_LEAVES = {
  pl: 10,
  sl: 2,
  cl: 3,
};
export const ON_SITE_EMPLOYEE_LEAVES = {
  ol: 10,
};
export const REMOTE_EMPLOYEE_LEAVES = {
  holiday: 4,
};
