import React from "react";
import { useForm } from "react-hook-form";
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { Button, Input } from "../../../components/ui";
import { useEmployeeContext } from "../../../providers/EditEmployeeProvider";
const AddressDetailsForm = ({ onNextStep, onPreviousStep }) => {
  const [control, handleSubmit] = useEmployeeContext()
  const onSubmit = (data) => {
    // console.log(data);
    onNextStep(); // Move to the next step
  };
  return (
    <>
      <div className="grid grid-cols-2 gap-6 mt-4">
        <div className="space-y-6">
          <Input
            control={control}
            label={"Address Line 1 "}
            name={"addressDetail.currentAddress.addressLine1"}
            placeholder={"Please enter address"}
            rules={{
              required: "Address is required",
            }}
          />
          <Input
            control={control}
            label={"Address Line 2 "}
            name={"addressDetail.currentAddress.addressLine2"}
            placeholder={"Please enter address"}
            rules={{
              required: false,
            }}
          />
          <Input
            control={control}
            label={"Pincode "}
            name={"addressDetail.currentAddress.pincode"}
            placeholder={"Please enter pincode"}
            rules={{
              required: "Pincode is required",
              maxLength: {
                value: 6,
                message: "Pincode should have at most 6 digits",
              },
              minLength: {
                value: 6,
                message: "Pincode should have at least 6 digits",
              },

              validate: {
                matchPattern: (v) =>
                  /^(\d{4}|\d{6})$/.test(v) || "Pincode must be valid",
              },
            }}
          />
          <Input
            control={control}
            label={"State "}
            name={"addressDetail.currentAddress.state"}
            placeholder={"Please enter state"}
            rules={{
              required: "State is required",
            }}
          />
          <Input
            control={control}
            label={"City "}
            name={"addressDetail.currentAddress.city"}
            placeholder={"Please enter city"}
            rules={{
              required: "City is required",
            }}
          />
        </div>
        <div className="space-y-6">
          <Input
            control={control}
            label={" Address Line 1 "}
            name={"addressDetail.permanentAddress.addressLine1"}
            placeholder={"Please enter address"}
            rules={{
              required: "Address Line 1 is required",
            }}
          />
          <Input
            control={control}
            label={"Address Line 2 "}
            name={"addressDetail.permanentAddress.addressLine2"}
            placeholder={"Please enter address"}
            rules={{
              required: false,
            }}
          />
          <Input
            control={control}
            label={"Pincode "}
            name={"addressDetail.permanentAddress.pincode"}
            placeholder={"Please enter pincode"}
            rules={{
              required: "Pincode is required",
            }}
          />
          <Input
            control={control}
            label={"State "}
            name={"addressDetail.permanentAddress.state"}
            placeholder={"Please enter state"}
            rules={{
              required: "State is required",
            }}
          />
          <Input
            control={control}
            label={"City "}
            name={"addressDetail.permanentAddress.city"}
            placeholder={"Please enter city"}
            rules={{
              required: "City is required",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center my-5 space-x-3 items-center mt-10">
        <Button variant={'outline'} onClick={onPreviousStep}>
          <ArrowLeftIcon className="mr-2 w-4 h-4" />
          Previous
        </Button>
        <Button className='' onClick={handleSubmit(onSubmit)}>
          Next
          <ArrowRightIcon className='w-4 h-4 ml-2' />
        </Button>
      </div>
    </>
  );
};

export default AddressDetailsForm;
