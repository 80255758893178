import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const EmployeeTab = ({ activeTab }) => {
    const { pathname } = useLocation()
    return (
        <div className='px-5 border-b border-gray-200 py-2 mt-10 mx-5'>
            <ul className='flex items-center space-x-6 text-gray-600'>
                <li className={`${pathname === '/employee-details' && 'active'}`}>
                    <Link to={`/employee-details`} >Employees</Link>
                </li>
                <li className={`${pathname === '/resigned-employee-details' && 'active'}`}>
                    <Link to={`/resigned-employee-details`}>Past Employees</Link>
                </li>
            </ul>
        </div>
    )
}

export default EmployeeTab