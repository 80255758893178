import "./loader.css";

const Spinner = ({ fullWidth = true }) => {
  return (
    <>
      {fullWidth ? (
        <div className="bg-white/60 fixed top-0 w-full h-full z-50 left-0">
          <span className="loader block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Spinner;
