import React, { useState } from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { Outlet } from 'react-router-dom'
import "./layout.css"

function Layout() {
    const [isSideBarShow, setIsSidebarShow] = useState(false)
    return (
        <div className='layout'>
            <Sidebar show={isSideBarShow} onClose={() => setIsSidebarShow(false)}/>
            <div className='content-wrapper lg:ml-64 mt-20'>
                <Header onClick={() => setIsSidebarShow(!isSideBarShow)} />
                <Outlet />
            </div>
        </div>
    )
}

export default Layout