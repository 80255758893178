import React, { useRef, useState } from "react";
import { Button } from "../ui"
import Webcam from "react-webcam";
import useUpload from "../../hooks/useUpload";

const PunchingButton = ({setUploadedImage}) => {
    const elementRef = useRef(null);
    const [openCamera, setOpenCamera] = useState(false)
    const [image, setImage] = useState({})
    const videoConstraints = {
        width: elementRef?.current?.offsetWidth,
        height: 300,
        facingMode: "user"
    };
    const webcamRef = React.useRef(null);
    const uploadDoc = useUpload({
        url: '/uploadImage',
        paramName: 'thumbnail[]',
        onSuccess: (res) => {
            setImage(res?.data?.data[0])
            setUploadedImage(res?.data?.data[0])
            setOpenCamera(false)
        }
    })
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, '');
            const blob = base64toBlob(base64Data, 'image/jpeg');
            const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
            let data = {
                screen: 'webcheckin',
                file
            }
            uploadDoc.mutate(data)
        },
        [webcamRef]
    );

    const base64toBlob = (base64Data, contentType) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    return (
        <div className=" mt-10" id="cam-wrapper" ref={elementRef}>
            {openCamera &&
                <div className="mb-4">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                </div>
            }
            <div className=" space-x-2 mb-4">
                {!openCamera &&
                    <Button onClick={() => setOpenCamera(true)} variant={'warning'}>
                        Open Camera
                    </Button>}
                {openCamera &&
                    <Button onClick={capture} variant={'warning'}>
                        Take Picture
                    </Button>}
                {openCamera &&
                    <Button onClick={() => setOpenCamera(false)} variant={'destructive'}>
                        Close
                    </Button>}
            </div>
            {image?.image_url &&
                <div className="w-[120px] h-[120px] mt-4">
                    <img src={image?.image_url} alt="" className="w-full h-full"/>
                    <button onClick={() => setImage({})} className=" bg-red-500 w-full py-1 rounded mt-2">Remove</button>
                </div>}
        </div>
    )
}

export { PunchingButton }