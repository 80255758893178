import React, { useRef } from 'react'
import Button from './Button'
import { UploadIcon } from '@heroicons/react/solid'

const FileUploader = ({
    variant = 'outline',
    className = "",
    label = 'Upload Education Documents',
    accept,
    multiple = false,
    onChange={onChange}
}) => {
    const fileInputRef = useRef(null)
    const handleGetFilesSelected = (e) => {
        let files = e.target.files
        onChange(files);
    }
    return (
        <div>
            <Button variant={variant} className={className} onClick={() => fileInputRef?.current?.click()}>
                <UploadIcon className="w-5 h-5 mr-2" />
                <span>{label}</span>
            </Button>
            <input type="file" className='hidden' onChange={handleGetFilesSelected} ref={fileInputRef} accept={accept} multiple={multiple} />
        </div>
    )
}

export default FileUploader