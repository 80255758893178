import { useMutation,useQueryClient } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../../config";
import axios from "../../api/axios";
const cookies = new Cookies();
const usePut = ({ url, onSuccess, onError,refreshUrl }) => {
  const queryClient = useQueryClient()
  const handleAuth = useMutation({
    mutationFn: (data) => handleFormSubmit(data),
    onSuccess: (data) => {
      onSuccess?.(data)
      {refreshUrl && queryClient.invalidateQueries(refreshUrl)}
    },
    onError: (err) => {
      onError?.(err)
    },
  })
  const handleFormSubmit = async (getFormData) => {
    let token = cookies.get(TOKEN_NAME);
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key]) || typeof getFormData[key] === 'object') {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }

    const response = await axios({
      method: 'POST',
      url: `/${url}`,
      data: fd,
      headers: { Authorization: token ? `Bearer ${token}` : '', }
    })
    return response
  }
  return { handleAuth }
}

export default usePut