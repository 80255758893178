import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import { getMetaData } from "../api";
import { DownloadIcon } from '@heroicons/react/solid';
import { ROLE } from "../config";
import Spinner from "../components/loader/Spinner";
import { Button } from "../components/ui";
const cookies = new Cookies
function Dashboard() {
  const [role, setRole] = useState('')
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    setRole(cookies.get(ROLE))
    getMetaData()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }, [])
  return (
    <>
      {isLoading ? <Spinner /> :
        <div className="px-4">
          {cookies.get(ROLE) === 'employee' ? 
          <> 
            <div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4">
                <Button>
                  <Link to={`/attendance-punch-in`}>
                    Punch Attendance
                  </Link>
                </Button>
                <Button>
                  <Link to={`/attendance-punch-in/logs`}>
                    View Punch-in Logs
                  </Link>
                </Button>
              </div>
              <div className="my-4 space-x-4">
                {data?.appointment_letter && <a className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded" href={data?.appointment_letter} download={true}>
                  <DownloadIcon className="w-6 h-6 inline-block" /> <span className="inline-block">Appointment Letter</span>
                </a>}
                {data?.confirmation_letter && <a className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded" href={data?.confirmation_letter} download={true}>
                  <DownloadIcon className="w-6 h-6 inline-block" /> <span className="inline-block">Confirmation Letter</span>
                </a>}
              </div>
            </div>
          </> 
          : cookies.get(ROLE) === 'consultant' ? <div><div className="grid grid-cols-4 gap-x-4">
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Other Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_ol_leave}</h1>
            </div>
            <div className="bg-orange-700 text-white text-center py-5 rounded">
              <h3 className="text-lg font-semibold">Discretionary Leaves Available</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_dl_leave}</h1>
            </div>

          </div>
          </div> : <div className="grid grid-cols-4 gap-4"><div className="bg-orange-700 text-white text-center py-5 rounded">
            <Link to={'/leave-applied'}>
              <h3 className="text-lg font-semibold">Pending Leaves</h3>
              <h1 className=" text-2xl font-semibold">{data?.pending_leave}</h1>
            </Link>
          </div></div>}
        </div>}
    </>
  );
}

export default Dashboard;
