import React from 'react'
import './radio.css'

function Radio({ label, name,value,onChange,val }) {
    return (
        <>
            <div className='roboto'>
                {val ?  <input type="radio" id={label} name={name} value={value} onChange={onChange}  checked={val === value}/> 
                :  <input type="radio" id={label} name={name} value={value} onChange={onChange}/>}
               
                <label htmlFor={label} className='ml-2'>{label}</label>
            </div>
        </>
    )
}

export default Radio