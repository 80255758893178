import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/solid';
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import { useParams } from 'react-router-dom'
import { formatTime } from '../../utility';
import Moment from 'react-moment';
import usePut from '../../hooks/usePut';
import toast, { Toaster } from 'react-hot-toast';
import PrimaryLoader from '../../components/common/PrimaryLoader';
import Pagination from '../../components/pagination';
import useRead from '../../hooks/useRead';

const AdvanceEmploymentEditing = ({ open, onClose, mode }) => {
  const { id, username } = useParams()
  const { list } = useRead({
    url: "shift_listing",
    method:'GET',
    initialData: {
      limit: 50,
      searchQuery: '',
    },
  })
  const { list: userShiftListing, paramsObject, setGetListParams } = useReadWithGet({
    url: "user_shift_listing",
    initialData: {
      limit: 100,
      searchQuery: '',
      user_id: id,
      page_number: 0
    }
  })
  const { handleAuth: submitEmployeeShift } = usePut({
    url: 'user_shift_update',
    refreshUrl: 'user_shift_listing',
    onSuccess: () => {
      toast.success('Employee Shift Updated')
      onClose()
    },
    onError: () => {
      toast.error('There was error while updating shift please try again later')
    }
  })
  const [shiftState, setShiftState] = useState({
    shift_id: '',
    user_id: id,
    effect_from: ''
  })
  const handleChangeShift = (e) => {
    let name = e.target.name;
    let value = e.target.value
    setShiftState(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  const handleUpdateShift = () => {
    const { shift_id, effect_from } = shiftState
    if (!shift_id || !effect_from) {
      return alert('Please Enter Shift and Effect From')
    }
    submitEmployeeShift.mutate(shiftState)
  }
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected
    }
    setGetListParams(data)
  }

  return (
    <>
      <Drawer anchor={'right'} open={open} onClose={onClose}>
        {submitEmployeeShift.isLoading &&
          <div className=' fixed w-full h-full top-0 left-0 flex items-center justify-center bg-[#ffffffab]'>
            <PrimaryLoader />
          </div>
        }
        <Toaster />
        <div className='h-full bg-white p-8' style={{ width: 'calc(100vw - 600px)' }}>
          <form className=' space-y-8'>
            <div>
              <div className='flex items-center justify-between space-x-3 pb-5 border-b border-gray-200'>
                <div className='flex items-center space-x-3'>
                  <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                  <p className='text-gray-700 text-xl font-semibold'>Assigning New Shift For {username}</p>
                </div>
                <div>
                  {mode !== 'resigned' &&
                    <button type='button' onClick={handleUpdateShift} className='px-5 py-2 bg-yellow-300 rounded font-semibold text-sm'>Update Shift</button>}
                </div>
              </div>
              <div className='grid grid-cols-3 gap-x-5 py-5'>
                <div>
                  <div className=''>
                    <label className='label'>Current Shift: </label>
                    <span className='block font-semibold text-base bg-red-100 text-red-600 py-2 rounded-md text-center'>
                      {userShiftListing?.data?.current_shift_data?.shiftName}
                    </span>
                  </div>
                </div>
                <div>
                  <div className=''>
                    <label className='label'>Shift Name: </label>
                    <select disabled={mode === 'resigned'} name="shift_id" onChange={handleChangeShift} className='input'>
                      <option value={shiftState.shift_id} >--Select Shift--</option>
                      {list.data?.data?.map((shiftData) => {
                        return <option key={shiftData.id} value={shiftData.id}>{shiftData.shiftName}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <label className='label'>Effect From </label>
                    <input disabled={mode === 'resigned'} value={shiftState.effect_from} onChange={handleChangeShift} type="date" name='effect_from' className='input' />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='flex items-center justify-between'>
                <p className='text-gray-700 text-3xl font-semibold mb-4'>Previous Assigned Shifts</p>
              </div>
              {userShiftListing.data?.data.length <= 0
                ? ''
                : <>
                  <div className="max-w-full overflow-x-auto mt-4">
                    <table className="table-auto w-full">
                      <thead>
                        <tr className=" bg-gray-200 text-center">
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Shift</th>
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Shift In-Time</th>
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Shift Out-Time</th>
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Effected From</th>
                          <th className="text-sm font-semibold text-gray-600 py-3 lg:py-2 px-3 lg:px-4 border-l border-gray-300">Effected Till</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userShiftListing.data?.data.map((item) => {
                          return <tr key={item.id}>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">{item.shiftName || '----'}</td>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.shiftInTime ? formatTime(item.shiftInTime) : '---'}
                            </td>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.shiftOutTime ? formatTime(item.shiftOutTime) : '---'}
                            </td>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.from_date ? <Moment unix date={item.from_date} format='DD/MM/YYYY' /> : '----'}
                            </td>
                            <td className="text-center text-dark font-medium text-xs py-2 px-2 bg-gray-50 border-b border-l border-gray-200">
                              {item.to_date ? <Moment unix date={item.to_date} format='DD/MM/YYYY' /> : '----'}
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='px-5 mt-5'>
                    <Pagination
                      currentPage={+paramsObject.page_number}
                      lengthofItems={userShiftListing.data?.total_count}
                      limit={paramsObject.limit}
                      onPageChange={handlePageClick}
                    />
                  </div>
                </>
              }
            </div>
          </form>

        </div>
      </Drawer>
    </>
  )
}

export default AdvanceEmploymentEditing