import React from 'react';
import { Button, Input } from '../../../components/ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { ADHAR_REGEX, PAN_REGEX } from '../../../utility';
import { useEmployeeContext } from '../../../providers/EditEmployeeProvider';
import usePut from '../../../hooks/put/usePut';
import Spinner from '../../../components/loader/Spinner';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const BankDetailsForm = ({ onPreviousStep }) => {
  const navigate = useNavigate();
  const params = useParams()
  const [control, handleSubmit] = useEmployeeContext()
  const {handleAuth: createEmployee} = usePut({
    url: 'employeedetails',
    onSuccess: (res) => {
      console.log(res); 
      navigate(-1)
      toast.success("Employee Created Successfully")
    },
    onError: (err) => {
      const {status,data} = err.response
      console.log(err);
      if(status >= 400 || status <= 499){
        console.log(data);
        toast.error(data?.message)
      }else if(status >= 500 || status <= 599){
        toast.error('Internal server error')
      }
    }
  })
  const {handleAuth: updateEmployee} = usePut({
    url: 'employeedetails_update',
    onSuccess: (res) => {
      console.log(res); 
      navigate(-1)
      toast.success("Employee Created Successfully")
    },
    onError: (err) => {
      const {status,data} = err.response
      console.log(err);
      if(status >= 400 || status <= 499){
        console.log(data);
        toast.error(data?.message)
      }else if(status >= 500 || status <= 599){
        toast.error('Internal server error')
      }
    }
  })
  const onSubmit = (data) => {
    let parsedData = convertDatesToUnix(data);
    // console.log(parsedData);
    // console.log(emp);
    if(params.id){
      let emp = {data:parsedData, user_id:params.id }
      updateEmployee.mutate(emp)
    }else{
      let emp = {data:parsedData}
      createEmployee.mutate(emp)
    }
    // onNextStep();

  };
  function convertDatesToUnix(obj) {
    // Helper function to recursively traverse the object
    function traverse(obj) {
      for (let key in obj) {
        if (obj[key] instanceof Object) {
          traverse(obj[key]); // Recursively traverse nested objects
        }  
        if (typeof obj[key] === 'object' && obj[key] instanceof Date) {
          obj[key] = Math.floor(obj[key].getTime() / 1000); // Convert Date object to Unix timestamp
        }
      }
    }
    traverse(obj); // Start traversing from the top-level object
    return obj;
  }
  return (
    <>
      {(createEmployee.isLoading) && <Spinner />}
      <div className='grid grid-cols-2 gap-6 mt-4'>
        <Input
          control={control}
          label={"Aadhaar card number "}
          name={"bankDetail.adhaarNumber"}
          placeholder={"Please enter Aadhaar card number"}
          rules={{
            required: "Aadhaar Card Number is required",
            pattern: {
              value: ADHAR_REGEX,
              message: "Aadhaar card number is invalid",
            },
          }}
        />
        <Input
          control={control}
          label={"PAN card number "}
          name={"bankDetail.panNumber"}
          placeholder={"Please enter PAN card number"}
          rules={{
            required: "Pan Card Number is required",
            pattern: {
              value: PAN_REGEX,
              message: "Pan card number is invalid",
            },
          }}
        />
        <Input
          control={control}
          label={'Bank name '}
          name={'bankDetail.bankName'}
          placeholder={'Please enter Bank name'}
          rules={{
            required: "Bank name is required",
          }} />
        <Input
          control={control}
          label={'Account Number '}
          name={'bankDetail.accountNumber'}
          placeholder={'Please enter account number'}
          rules={{
            required: "Account Number is required",
          }} />
        <Input
          control={control}
          label={'IFSC Code '}
          name={'bankDetail.ifsc'}
          placeholder={'Please enter ifsc code'}
          rules={{
            required: "IFSC Code is required",
          }} />

      </div>
      <div className='flex justify-center my-5 space-x-3 items-center'>
        <Button variant={'outline'} onClick={onPreviousStep}>
          <ArrowLeftIcon className="mr-2 w-4 h-4" />
          Previous
        </Button>
        <Button className='' onClick={handleSubmit(onSubmit)}>
          Next
          <ArrowRightIcon className='w-4 h-4 ml-2' />
        </Button>
      </div>
    </>
  )
}

export default BankDetailsForm