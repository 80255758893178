import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import LikertView from '../../components/likert';
import usePut from '../../hooks/usePut'
import toast, { Toaster } from 'react-hot-toast';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Button } from '../../components/ui';

const AdvanceShiftEditing = ({ open, onClose, shiftData, mode }) => {
  // console.log(shiftData);
  const weekendObject = {
    sunday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    monday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    tuesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    wednesday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    thursday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    friday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ],
    saturday: [
      {
        type: 1,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 2,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 3,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 4,
        weekendDay: 'full_day',
        isSelected: false
      },
      {
        type: 5,
        weekendDay: 'full_day',
        isSelected: false
      },
    ]
  }
  let formData = {
    shiftName: '',
    shiftInTime: '',
    shiftOutTime: '',
    workingTypeMode: '',  // strict | lenient
    minimum_working_hours: '',
    maximum_working_hours: '',
    weekendDefinition: weekendObject,
    first_half_in_time: '',
    first_half_out_time: '',
    second_half_in_time: '',
    second_half_out_time: ''
  }

  const [shiftFormData, setShiftFormData] = useState(formData)
  useEffect(() => {
    if (shiftData) {
      const { weekendDefinition } = shiftData || {}
      let newWeekendDefinition = JSON.parse(JSON.stringify(weekendObject))
      Object.keys(newWeekendDefinition).forEach((weekEndKey) => {
        if (weekEndKey in weekendDefinition) {
          console.log(weekendDefinition);
          weekendDefinition[weekEndKey].forEach((weekEndData, idx) => {
            if (newWeekendDefinition[weekEndKey][idx].type === +weekEndData.type || +weekEndData.type === NaN) {
              newWeekendDefinition[weekEndKey][idx] = weekEndData
            }
          })
        }
      })
      let data = {
        ...shiftData,
        weekendDefinition: newWeekendDefinition
      }
      setShiftFormData(data)
    }
  }, [shiftData])
  const handleSetShiftFormData = (e) => {
    let name = e.target.name
    let value = e.target.value
    let prevShiftData = shiftFormData
    prevShiftData[name] = value
    setShiftFormData({ ...shiftFormData })
  }
  const handleUpdateWeekendDefinition = (object) => {
    let prevShiftData = shiftFormData
    prevShiftData.weekendDefinition = object
    setShiftFormData({ ...shiftFormData })
  }
  const { handleAuth } = usePut({
    url: 'shift_create',
    refreshUrl: 'shift_listing',
    onSuccess: () => {
      toast.success('Shift Created Successfully')
      onClose()
      clearData()
    },
    onError: (err) => {
      const {status,data} = err
      if(status >= 400 || status <= 499){
        toast.error(data.message)
      }else{
        toast.error('Internal Server Error')
      }
    }
  })
  const handleSubmitShiftData = (e) => {
    e.preventDefault()
    if (shiftFormData.workingTypeMode === 'strict') {
      delete shiftFormData.working_hours
    } else {
      delete shiftFormData.shiftInTime
      delete shiftFormData.shiftOutTime
      delete shiftFormData.first_half_in_time
      delete shiftFormData.first_half_out_time
      delete shiftFormData.second_half_in_time
      delete shiftFormData.second_half_out_time
    }
    // console.log(shiftFormData);
    handleAuth.mutate(shiftFormData)
  }
  const handleCloseDialog = () => {
    clearData()
    onClose()
  }
  const clearData = () => {
    setShiftFormData(formData)
  }

  function hasSelectedTrue(obj) {
    for (const day in obj) {
      if (obj[day].some(item => item.weekendDay === 'first_half')) {
        return true;
      }
    }
    return false;
  }
  function hasSelectedSecondHalfTrue(obj) {
    for (const day in obj) {
      if (obj[day].some(item => item.weekendDay === 'second_half')) {
        return true;
      }
    }
    return false;
  }
  return (
    <>
      <Toaster />
      <Drawer anchor={'right'} open={open} onClose={handleCloseDialog}>
        <div className='h-full bg-white px-8' style={{ width: 'calc(100vw - 250px)' }}>
          <form className=' space-y-8' onSubmit={handleSubmitShiftData}>
            <div className='flex items-center justify-between  py-5 border-b border-gray-200 sticky z-40 top-0 bg-white'>
              <div className='flex items-center space-x-3'>
                <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={handleCloseDialog} />
                <p className='text-gray-700 text-3xl font-semibold'>Shift Details</p>
              </div>
              {/* {mode !== 'edit' &&
                <button className=' bg-color1 px-5 py-2 text-lg text-white rounded-md'>Save Shift</button>} */}
              {/* <button className=' bg-color1 px-5 py-2 text-lg text-white rounded-md'>Save Shift</button> */}
              <Button className={'px-4 py-2'} type='submit'>
                Save Shift
              </Button>
            </div>
            <div className=' '>
              <div className='grid grid-cols-3 gap-5 py-5'>
                <div>
                  <div className=''>
                    <label className='label'>Shift Name: </label>
                    <input type="text" name='shiftName' disabled={mode === 'edit'} value={shiftFormData.shiftName} onChange={handleSetShiftFormData} required={true} className='input' placeholder='Enter Shift name ' />
                  </div>
                </div>
                <>
                  <div className="px-4 col-span-2">
                    <p className='label'>Working Type:</p>
                    <div className=" flex gap-x-4" >
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="workingTypeMode"
                        value={shiftFormData.workingTypeMode}
                        onChange={handleSetShiftFormData}
                      >
                        <FormControlLabel value="strict" control={<Radio />} label="Fixed" />
                        <FormControlLabel value="lenient" control={<Radio />} label="Flexible" />
                      </RadioGroup>
                    </div>
                  </div>
                  {shiftFormData.workingTypeMode === 'strict'
                    ? <>
                      <div>
                        <div>
                          <label className='label'>Shift In-Time: </label>
                          <input type="time" name='shiftInTime' value={shiftFormData.shiftInTime} onChange={handleSetShiftFormData} required={true} className='input' />
                        </div>
                      </div>
                      <div>
                        <div>
                          <label className='label'>Shift Out-Time: </label>
                          <input type="time" name='shiftOutTime' required={true} value={shiftFormData.shiftOutTime} onChange={handleSetShiftFormData} className='input' />
                        </div>
                      </div>
                    </>
                    : shiftFormData.workingTypeMode === 'lenient' ?
                      <div className=' col-span-3'>
                        <div className=' grid grid-cols-3 gap-4'>
                          <div>
                            <label className='label'>Minimum Monthly Working Hours: </label>
                            <input type="number" name='minimum_working_hours' required={true} value={shiftFormData.minimum_working_hours} onChange={handleSetShiftFormData} className='input' />
                            <small className=' text-red-500 font-semibold'>Enter in hours</small>
                          </div>
                          <div>
                            <label className='label'>Maximum Monthly Working Hours: </label>
                            <input type="number" name='maximum_working_hours' required={true} value={shiftFormData.maximum_working_hours} onChange={handleSetShiftFormData} className='input' />
                            <small className=' text-red-500 font-semibold'>Enter in hours</small>
                          </div>
                        </div>
                      </div>
                      : null
                  }
                </>
              </div>
              {shiftFormData.workingTypeMode === 'strict' &&
                <div>
                  <div className="grid grid-cols-3 gap-5 py-5">
                    <div>
                      <div>
                        <label className='label'>First Half In-Time: </label>
                        <input type="time" name='first_half_in_time' value={shiftFormData.first_half_in_time} onChange={handleSetShiftFormData} required={hasSelectedTrue(shiftFormData.weekendDefinition)} className='input' />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='label'>First Half Out-Time: </label>
                        <input type="time" name='first_half_out_time' required={hasSelectedTrue(shiftFormData.weekendDefinition)} value={shiftFormData.first_half_out_time} onChange={handleSetShiftFormData} className='input' />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-5 py-5">
                    <div>
                      <div>
                        <label className='label'>Second Half In-Time: </label>
                        <input type="time" name='second_half_in_time' value={shiftFormData.second_half_in_time} onChange={handleSetShiftFormData} required={hasSelectedSecondHalfTrue(shiftFormData.weekendDefinition)} className='input' />
                      </div>
                    </div>
                    <div>
                      <div>
                        <label className='label'>Second Half Out-Time: </label>
                        <input type="time" name='second_half_out_time' required={hasSelectedSecondHalfTrue(shiftFormData.weekendDefinition)} value={shiftFormData.second_half_out_time} onChange={handleSetShiftFormData} className='input' />
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
            {shiftFormData.workingTypeMode !== 'lenient' &&
              <div className='py-5'>
                <p className='text-gray-700 text-3xl font-semibold mb-4'>Weekend Definition</p>
                <LikertView
                  mode={mode}
                  onChange={(values) => handleUpdateWeekendDefinition(values)}
                  values={shiftFormData.weekendDefinition}
                />
              </div>}
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default AdvanceShiftEditing