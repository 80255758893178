import { Button, DateInput, Input, Select } from "../../../components/ui";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useEmployeeContext } from "../../../providers/EditEmployeeProvider";
// import { useEmployeeContext } from "../../../providers/EmployeeProvider";

const PersonalDetailsForm = ({ onNextStep }) => {
  const [control, handleSubmit, setValue] = useEmployeeContext()
  const onSubmit = (data) => {
    // console.log(data);
    setValue('personalDetail.profileImageId', '')
    onNextStep(); // Move to the next step
  };
  return (
    <>
      <div className="grid grid-cols-2 gap-6 items-end">
        <Input
          control={control}
          label={"First name"}
          name={"personalDetail.firstName"}
          placeholder={"Please enter first name"}
          rules={{
            required: "First name is required",
          }}
        />
        <Input
          control={control}
          label={"Last name "}
          name={"personalDetail.lastName"}
          placeholder={"Please enter last name"}
          rules={{
            required: "Last name is required",
          }}
        />
        <DateInput
          control={control}
          label={"Date of Birth "}
          name={"personalDetail.dateOfBirth"}
          rules={{
            required: "Date of Birth  is required",
          }}
        />
        <Select
          control={control}
          label={"Gender"}
          name={"personalDetail.gender"}
          options={[
            { label: "Male", value: "male" },
            { label: "Female", value: "female" },
          ]}
          rules={{
            required: "Gender  is required",
          }}
        />
        <Input
          control={control}
          label={"Email "}
          name={"personalDetail.email"}
          placeholder={"Please enter email"}
          rules={{
            required: "Email is required",
            validate: {
              maxLength: (v) =>
                v.length <= 50 || "The email should have at most 50 characters",
              matchPattern: (v) =>
                /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) ||
                "Email address must be a valid address",
            },
          }}
        />
        <Input
          control={control}
          label={"Mobile number "}
          name={"personalDetail.mobileNumber"}
          placeholder={"Please enter mobile number"}
          rules={{
            required: "Mobile number is required",
            maxLength: {
              value: 10,
              message: "Mobile number should have at most 10 digits",
            },
            minLength: {
              value: 10,
              message: "Mobile number should have at least 10 digits",
            },
            validate: {
              matchPattern: (v) =>
                /^(0|[1-9]\d*)(\.\d+)?$/.test(v) ||
                "Mobile number must be valid",
            },
          }}
        />
        <Input
          control={control}
          label={"Alternate number "}
          name={"personalDetail.alternateMobileNumber"}
          placeholder={"Please enter alternate number"}
        />
        <Input
          control={control}
          label={"Blood Group "}
          name={"personalDetail.bloodGroup"}
          placeholder={"Please enter Blood Group"}
          rules={{
            required: "Blood Group is required",
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 items-end my-8">
        <div>
          <h1 className="font-semibold text-xl mb-6 underline">Emergency Contact Details</h1>
          <Input
            control={control}
            label={"Name "}
            name={"personalDetail.emergencyContactPersonName"}
            placeholder={"Please enter Name"}
            rules={{
              required: "Name is required",
            }}
          />
        </div>
        <div>
          <Input
            control={control}
            label={"Mobile Number "}
            name={"personalDetail.emergencyContactPersonMobileNumber"}
            placeholder={"Please enter Mobile Number"}
            rules={{
              required: "Mobile number is required",
              maxLength: {
                value: 10,
                message: "Mobile number should have at most 10 digits",
              },
              minLength: {
                value: 10,
                message: "Mobile number should have at least 10 digits",
              },
              validate: {
                matchPattern: (v) =>
                  /^(0|[1-9]\d*)(\.\d+)?$/.test(v) ||
                  "Mobile number must be valid",
              },
            }}
          />
        </div>
      </div>
      <div className="flex justify-end mt-5 items-center">
        <Button className="" onClick={handleSubmit(onSubmit)}>
          Next
          <ArrowRightIcon className="w-4 h-4 ml-2" />
        </Button>
      </div>
    </>
  );
};

export default PersonalDetailsForm;
