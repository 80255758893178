import React from "react";
import Label from "./label";
import { cn } from "../../utility/utils";
import { Controller } from "react-hook-form";

const Select = ({
  className,
  type,
  label,
  control,
  rules,
  name,
  options,
  customKey,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <div className="flex flex-col space-y-2">
            <Label>
              {label}
              {rules?.required && <span className=" text-red-600">*</span>}
            </Label>
            <select
              className={cn(
                "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
                className
              )}
              value={value}
              onChange={onChange}
              {...props}
            >
              <option value="">--Select {label}--</option>
              {options?.map((option, idx) => {
                const { label, value } = option;
                return (
                  <option key={idx} value={customKey?.value ? option[customKey?.value] : value} className="capitalize">
                    {customKey?.label ? option[customKey?.label] : label}
                  </option>
                );
              })}
            </select>
            {error && (
              <small className="text-red-600 font-medium block pt-1 text-sm">
                {error.message}
              </small>
            )}
          </div>
        </>
      )}
    />
  );
};

export default Select;
