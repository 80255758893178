import { Modal } from '@mui/material'
import { useForm } from 'react-hook-form';
import usePut from '../../hooks/put/usePut';
import { XIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { Button, Input, Select } from '../../components/ui';
import { RxReload } from 'react-icons/rx';
import { useEffect } from 'react';

const EditDepartment = ({ open, onClose, shiftData,selectedDepartment }) => {
    const { control, handleSubmit, reset, setValue } = useForm();
    useEffect(() => {
        setValue('department_name', selectedDepartment.departmentName)
        setValue('shift_id', selectedDepartment.shiftId)
    },[selectedDepartment])
    const { handleAuth: editDepartment } = usePut({
        url: 'department_update',
        onSuccess: (res) => {
            onClose()
            toast.success("Department Updated Successfully")
            reset();
        },
        onError: (err) => {
            const { status, data } = err.response
            if (status >= 400 || status <= 499) {
                toast.error(data?.message)
            } else if (status >= 500 || status <= 599) {
                toast.error('Internal server error')
            }
        },
        refreshUrl: 'department_listing'
    })
    const handleSaveDepartment = (data) => {
        let editData = {
            ...data,
            id: selectedDepartment.id
        }
        editDepartment.mutate(editData)
    }
    return (
        <Modal open={open} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                <div className='document-wrapper px-4 divide-y'>
                    <div className="document-head py-4">
                        <div className='flex items-center justify-between'>
                            <h3 className=' text-gray-700 text-3xl font-semibold'>Edit Department</h3>
                            <div className=' w-6 h-6 cursor-pointer' onClick={onClose}>
                                <XIcon className=' text-black w-full h-full' />
                            </div>
                        </div>
                    </div>
                    <div className="document-body py-4 max-h-96 overflow-auto">
                        <form className=' space-y-5'>
                            <Input
                                control={control}
                                label={"Department Name"}
                                name={"department_name"}
                                placeholder={"Please enter department name"}
                                rules={{
                                    required: "Department name is required",
                                }}
                            />
                            <Select
                                control={control}
                                label={"Shift"}
                                name={"shift_id"}
                                options={shiftData}
                                rules={{
                                    required: "Shift is required",
                                }}
                                customKey={{
                                    label: "shiftName",
                                    value: "id",
                                }}
                            />
                        </form>
                    </div>
                    <div className="document-foot space-x-4 py-4">
                        <div className="grid grid-cols-4 gap-3">
                            <Button disabled={editDepartment.isLoading} onClick={handleSubmit(handleSaveDepartment)}>
                                Save
                                {editDepartment.isLoading && <RxReload className=' ml-2 w-4 h-4 animate-spin' />}
                            </Button>
                            <Button variant={'outline'} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditDepartment