import { cva } from "class-variance-authority";
import { cn } from "../../utility/utils";

const labelVariants = cva(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);

const Label = ({children}) => {
  return (
    <label className={cn(labelVariants())}>
      {children}
    </label>
  );
};

export default Label;
