import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { PlusIcon } from '@heroicons/react/solid'
import useRead from '../../hooks/useRead'
import usePut from '../../hooks/usePut'
import toast, { Toaster } from 'react-hot-toast'
import InlineLoader from '../../components/loader/InlineLoader'

const Employment = () => {
    const [isOpenAdvanceEmployment, setIsOpenAdvanceEmployment] = useState(false)
    const [isOpenCreateAdvanceEmployment, setIsOpenCreateAdvanceEmployment] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [name, setName] = useState('')
    const [type_of_employee, setType_of_employee] = useState('')
    const handleClickedAdvanceShift = (id) => {
        setIsOpenAdvanceEmployment(true)
        let data = employmentList.data?.data.find(x => x.id === id)
        setSelectedId(data.id)
        setName(data.name)
        setType_of_employee(data.type_of_employee)
    }
    const create = usePut({
        url: 'employment_create_and_update',
        refreshUrl: 'employment_listing',
        onSuccess: () => {
            toast.success('Employment Add Successfully')
            setIsOpenAdvanceEmployment(false)
            setIsOpenCreateAdvanceEmployment(false)
        }
    })
    const { list: employmentList, paramsObject, setGetListParams } = useRead({
        url: 'employment_listing',
        initialData: {
            limit: 50,
            search_query: ''
        }
    })
    const handleCreateOrUpdateForm = (e) => {
        e.preventDefault()
        let data = {
            name,
            type_of_employee,
            id: selectedId || ''
        }
        create.handleAuth.mutate(data)
    }

    const handleCloseModal = () => {
        setIsOpenAdvanceEmployment(false)
        setIsOpenCreateAdvanceEmployment(false)
        setSelectedId(null)
        setName('')
    }
    return (
        <>
            <Toaster/>
            <div className='p-5'>
                <div>
                    <button onClick={() => setIsOpenCreateAdvanceEmployment(true)} className=' rounded-md bg-color1 px-8 py-2 text-white'>
                        <div className='flex items-center justify-center space-x-2'>
                            <PlusIcon className='w-6 h-6' />
                            <span className=' font-semibold'>Add Employment</span>
                        </div>
                    </button>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                    {employmentList.data?.data.map((item) => {
                        const { name, id } = item
                        return <div key={id} className='w-full rounded-md border border-gray-300 p-4'>
                            <div>
                                <p className=''>
                                    <label className='text-gray-700 font-semibold text-lg'>Employee Type: </label>
                                    <span>{name}</span>
                                </p>
                                <button onClick={() => handleClickedAdvanceShift(id)} className=' px-6 py-2 bg-cyan-500 text-white rounded-md text-xs mt-5'>Edit Employment</button>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {isOpenAdvanceEmployment &&
                <div className='fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-[#ffffff96]'>
                    <div className='w-[30%] bg-white rounded-md shadow-xl p-4'>
                        <div className='pb-3 border-b border-gray-200'>
                            <h2 className=' font-semibold text-xl'>Edit Employment</h2>
                        </div>
                        <form className='pt-3 space-y-4' onSubmit={handleCreateOrUpdateForm}>
                            <div className=''>
                                <label className='label text-base'>Employment Name: </label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" name='title' required={true} className='input' placeholder='Enter Employment name ' />
                            </div>
                            <div className=''>
                                <label className='label text-base'>Employee Type: </label>
                                <select name="" className='input' required={true} value={type_of_employee}onChange={(e) => setType_of_employee(e.target.value)}>
                                    <option value="">--Select Employee Type--</option>
                                    <option value="employee">Employee</option>
                                    <option value="consultant">Consultant</option>
                                </select>
                            </div>
                            <div className='text-center mt-4 space-x-4'>
                                <button className=' rounded-md bg-color1 px-8 py-2 text-white'>
                                    <div className='flex items-center justify-center space-x-2'>
                                        <span className=' font-semibold text-xs'>Update Employment</span>
                                        {create.handleAuth.isLoading && <InlineLoader/>}
                                    </div>
                                </button>
                                <button onClick={handleCloseModal} type='button' className=' rounded-md border border-color1 px-8 py-2 text-black'>
                                    <div className='flex items-center justify-center space-x-2'>
                                        <span className=' font-semibold text-xs'>Close</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>}
            {isOpenCreateAdvanceEmployment &&
                <div className='fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-[#ffffff96]'>
                    <div className='w-[30%] bg-white rounded-md shadow-xl p-4'>
                        <div className='pb-3 border-b border-gray-200'>
                            <h2 className=' font-semibold text-xl'>Create Employment</h2>
                        </div>
                        <form className='pt-3 space-y-4' onSubmit={handleCreateOrUpdateForm}>
                            <div className=''>
                                <label className='label text-base'>Employment Name: </label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" name='title' required={true} className='input' placeholder='Enter Employment name ' />
                            </div>
                            <div className=''>
                                <label className='label text-base'>Employee Type: </label>
                                <select name="" className='input' required={true} value={type_of_employee} onChange={(e) => setType_of_employee(e.target.value)}>
                                    <option value="">--Select Employee Type--</option>
                                    <option value="employee">Employee</option>
                                    <option value="consultant">Consultant</option>
                                </select>
                            </div>
                            <div className='text-center mt-4 space-x-4'>
                                <button className=' rounded-md bg-color1 px-8 py-2 text-white'>
                                    <div className='flex items-center justify-center space-x-2'>
                                        <span className=' font-semibold text-xs'>Save Employment</span>
                                        {create.handleAuth.isLoading && <InlineLoader/>}
                                    </div>
                                </button>
                                <button onClick={handleCloseModal} type='button' className=' rounded-md border border-color1 px-8 py-2 text-black'>
                                    <div className='flex items-center justify-center space-x-2'>
                                        <span className=' font-semibold text-xs'>Close</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>}
        </>
    )
}

export default Employment