import React, { useState } from "react";
import {
  Button,
  DateInput,
  FileUploader,
  Input,
  Select,
} from "../../../components/ui";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { useEmployeeContext } from "../../../providers/EditEmployeeProvider";
import useUpload from "../../../hooks/useUpload";
import Spinner from "../../../components/loader/Spinner";
import DocumentsDrawer from "./DocumentsDrawer";
import Checkbox from "../../../components/ui/Checkbox";
import { useParams } from "react-router-dom";

const WorkDetailsForm = ({
  onNextStep,
  onPreviousStep,
  employmentList,
  shiftList,
  departmentListing,
}) => {
  const { id } = useParams();
  const [fileType, setFileType] = useState("");
  const [
    control,
    handleSubmit,
    setValue,
    getValues,
    educationDoc,
    setEducationDoc,
    workDoc,
    setWorkDoc,
  ] = useEmployeeContext();
  const onSubmit = () => {
    setValue("workDetail.previousWorkDetail.educationDocuments", educationDoc);
    setValue("workDetail.previousWorkDetail.workDocuments", workDoc);
    onNextStep();
  };

  const upload = useUpload({
    onSuccess: (res) => {
      const files = res.data.data;
      if (fileType === "education") {
        setEducationDoc(files);
      } else {
        setWorkDoc(files);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const handleGetSelectedFiles = (files, type) => {
    setFileType(type);
    const fileData = {
      files,
      screen: "employee",
    };
    upload.mutate(fileData);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const [docType, setDocType] = useState("");
  const handleSetDocumentDataToDrawer = (type) => {
    setOpenDrawer(true);
    setDocType(type);
  };
  const handleRemoveDoc = (imageID) => {
    setEducationDoc([]);
    const array = docType === "education" ? educationDoc : workDoc;
    const index = array.findIndex((item) => item.image_id === imageID);
    if (index !== -1) {
      array.splice(index, 1);
    }
    if (docType === "education") {
      setEducationDoc(array);
    } else {
      setWorkDoc(array);
    }
  };
  return (
    <>
      <div>
        {upload?.isLoading && <Spinner />}
        <div className=" space-y-8">
          <div>
            <h1 className="font-semibold text-xl mb-6 underline">
              Current Work Details
            </h1>
            <div className="grid grid-cols-2 gap-6 items-end">
              <Input
                control={control}
                label={"Designation"}
                name={"workDetail.currentWorkDetail.designation"}
                placeholder={"Please enter designation"}
                rules={{
                  required: "Designation is required",
                }}
              />
              {!id && (
                <Select
                  control={control}
                  label={"Department"}
                  name={"workDetail.currentWorkDetail.department"}
                  options={departmentListing}
                  customKey={{
                    label: "departmentName",
                    value: "id",
                  }}
                  rules={{
                    required: "Department is required",
                  }}
                />
              )}

              <Select
                control={control}
                label={"Employment Type"}
                name={"workDetail.currentWorkDetail.employmentType"}
                options={employmentList}
                rules={{
                  required: "Employment Type is required",
                }}
                customKey={{
                  label: "name",
                  value: "id",
                }}
              />
              {!id && (
                <Select
                  control={control}
                  label={"Shift"}
                  name={"workDetail.currentWorkDetail.shift"}
                  options={shiftList}
                  rules={{
                    required: false,
                  }}
                  customKey={{
                    label: "shiftName",
                    value: "id",
                  }}
                />
              )}

              <DateInput
                control={control}
                label={"Date Of Joining "}
                name={"workDetail.currentWorkDetail.dateOfJoining"}
                rules={{
                  required: "Date Of Joining is required",
                }}
              />
              <DateInput
                control={control}
                label={"Probation Date "}
                name={"workDetail.currentWorkDetail.probationDate"}
              />
            </div>
          </div>
          <div>
            <h1 className="font-semibold text-xl mb-6 underline">
              Previous Work Details
            </h1>
            <div className=" space-y-6">
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  label={"Education detail"}
                  name={"workDetail.previousWorkDetail.education"}
                  placeholder={"Please enter education detail"}
                  rules={{
                    required: "Education detail is required",
                  }}
                />
                {!id && (
                  <div className="flex items-center space-x-2">
                    <div className="h-10">
                      <FileUploader
                        multiple
                        accept={".pdf, image/png, image/jpeg, image/jpg"}
                        onChange={(files) =>
                          handleGetSelectedFiles(files, "education")
                        }
                      />
                    </div>
                    {educationDoc?.length > 0 && (
                      <Button
                        variant={"warning"}
                        className=""
                        onClick={() =>
                          handleSetDocumentDataToDrawer("education")
                        }
                      >
                        Documents
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  label={"Work Experience (in years)"}
                  name={"workDetail.previousWorkDetail.workExperience"}
                  placeholder={"Please enter work experience"}
                  rules={{
                    required: "Work Experience detail is required",
                  }}
                />
                {!id && (
                  <div className="flex items-center space-x-2">
                    <div className="h-10">
                      <FileUploader
                        label="Upload Work Experience Documents"
                        multiple
                        accept={".pdf, image/png, image/jpeg, image/jpg"}
                        onChange={(files) =>
                          handleGetSelectedFiles(files, "work")
                        }
                      />
                    </div>
                    {workDoc?.length > 0 && (
                      <Button
                        variant={"warning"}
                        className=""
                        onClick={() => handleSetDocumentDataToDrawer("work")}
                      >
                        Documents
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className=" mb-6">
              <h1 className="font-semibold text-xl underline">
                Statutory Components
              </h1>
              <small>
                Enable the necessary benefits and tax applicable for this
                employee.
              </small>
            </div>

            <div className=" mt-5">
              <p className=" mb-4 font-semibold">Employee Provident Fund</p>
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  label={"PF Account Number:"}
                  name={
                    "workDetail.statutory.employeeProvidentFund.providentFundNumber"
                  }
                  placeholder={"AA/AAA/0000000/000/0000000"}
                  rules={{
                    required: false,
                  }}
                />
                <Input
                  control={control}
                  label={"UAN"}
                  name={"workDetail.statutory.employeeProvidentFund.UAN"}
                  placeholder={"0000"}
                  rules={{
                    required: false,
                  }}
                />
              </div>
            </div>
            <div className=" mt-5">
              <p className=" mb-4 font-semibold">Employees' State Insurance</p>
              <div className="grid grid-cols-2 gap-6 items-end">
                <Input
                  control={control}
                  label={"ESI Insurance Number:"}
                  name={
                    "workDetail.statutory.employeeStateIndurance.induranceNumber"
                  }
                  placeholder={"0000"}
                  rules={{
                    required: false,
                  }}
                />
              </div>
              <small className=" text-xs text-gray-500">
                Note: ESI deductions will be made only if the employee’s monthly
                salary is less than or equal to ₹21,000
              </small>
            </div>
            <div className=" mt-5">
              <Checkbox
                control={control}
                name={"workDetail.statutory.professionalTax"}
                label={"Professional Tax"}
              />
              <small className=" block text-gray-400">{`Note: Professional Tax deductions`}</small>
              <small className=" block text-gray-400">{`Male employee if salary between ₹ 7,500 - ₹ 10,000 - (₹175) will be deducted if salary greater then ₹ 10,000 - (₹200) will be deducted`}</small>
              <small className=" block text-gray-400">{`Female employee if salary between ₹ 7,500 - ₹ 25,000 - (₹0) will be deducted if salary greater then ₹ 25,001 - (₹200) will be deducted`}</small>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-10 space-x-3 items-center">
          <Button variant={"outline"} onClick={onPreviousStep}>
            <ArrowLeftIcon className="mr-2 w-4 h-4" />
            Previous
          </Button>
          <Button className="" onClick={handleSubmit(onSubmit)}>
            Next
            <ArrowRightIcon className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
      <DocumentsDrawer
        onRemoveDoc={handleRemoveDoc}
        data={docType === "education" ? educationDoc : workDoc}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
    </>
  );
};

export default WorkDetailsForm;
